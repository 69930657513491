<template>
  <div class="ts-container">

    <section class="cases-page">
      <div class="breadcrumb">
        <a class="breadcrumb-main" href="/">{{$t('Главная')}}</a>
        <a class="breadcrumb-active" href="/cases">{{$t('Кейсы')}}</a>
      </div>
      <div class="nav-bar">
        <div @click="currentFilter='all'" :class="{'nav-bar-item-active' :currentFilter=='all'}" class="nav-bar-item">{{$t('Все')}}</div>
        <div @click="currentFilter='site'" :class="{'nav-bar-item-active' :currentFilter=='site'}" class="nav-bar-item">{{$t('Сайты')}}</div>
        <div @click="currentFilter='mobile'" :class="{'nav-bar-item-active' :currentFilter=='mobile'}" class="nav-bar-item ">{{$t('МобильныеПриложения')}}</div>
        <div @click="currentFilter='portal'" :class="{'nav-bar-item-active' :currentFilter=='portal'}" class="nav-bar-item">{{$t('Порталы')}}</div>
      </div>
      <div class="project-wrapper-btn-back">
        <a href="/">Вернуться назад</a>
      </div>
     <div class="case-wrap">
       <div class="case-item" v-for="(item,index) in filteredCase" :key="item.name+index">
         <div class="case-text">
           <div :style="{color:item.color}" class="case-text-name">{{item.name}}</div>
           <div class="case-text-block" v-html="item.description"></div>
           <div  :style="{color:item.color}" class="case-text-devType">{{item.devType}}</div>
           <div class="case-text-tech-wrap">
           <div :style="{color:item.color}" class="case-text-tech-item" v-for="tech in item.tech" :key="tech">{{tech}}</div>
           </div>
         </div>
         <div class="case-product">
           <div class="case-product-image" :style="{backgroundImage: `url(${item.image})`}"></div>
           <div   class="case-product-url-wrapper">
           <a  v-if="item.appStore" :href="item.appStore" class="case-mobile-url" >
             <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
               <circle cx="18" cy="18" r="18" :fill="item.color"/>
               <circle cx="18" cy="18" r="18" :fill="item.color"/>
               <path d="M23.3129 17.6359C23.3328 16.092 24.162 14.6311 25.4773 13.8225C24.6475 12.6374 23.2577 11.886 21.8117 11.8408C20.2694 11.6789 18.7742 12.7637 17.9883 12.7637C17.1871 12.7637 15.9771 11.8569 14.674 11.8837C12.9756 11.9385 11.3922 12.9042 10.5659 14.3891C8.78963 17.4644 10.1146 21.9841 11.8161 24.47C12.6674 25.6873 13.6623 27.047 14.9642 26.9988C16.2381 26.9459 16.7139 26.1864 18.2517 26.1864C19.7752 26.1864 20.2215 26.9988 21.5498 26.9681C22.9168 26.9459 23.7781 25.7454 24.5995 24.5166C25.2112 23.6493 25.6819 22.6907 25.9941 21.6763C24.3876 20.9969 23.3148 19.3802 23.3129 17.6359Z" fill="white"/>
               <path d="M20.8041 10.2059C21.5494 9.31119 21.9166 8.16113 21.8277 7C20.689 7.1196 19.6371 7.66383 18.8817 8.52426C18.143 9.36494 17.7586 10.4948 17.8313 11.6115C18.9704 11.6232 20.0902 11.0938 20.8041 10.2059Z" fill="white"/>
               <defs>
                 <linearGradient id="paint0_linear_173_1676" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
                   <stop :stop-color="item.color"/>
                   <stop offset="1" :stop-color="item.color"/>
                 </linearGradient>
               </defs>
             </svg>
              <span class="case-mobile-url-text" :style="{color:item.color}">App Store</span>
           </a>
           <a v-if="item.googlePlay" :href="item.googlePlay" class="case-mobile-url">
             <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
               <circle cx="18" cy="18" r="18" :fill="item.color"/>
               <circle cx="18" cy="18" r="18"  :fill="item.color"/>
               <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1423 8.64262C10.0506 8.8505 10 9.10513 10 9.40126V26.5983C10 26.8957 10.0509 27.1509 10.1431 27.3589L19.5001 18.0005L10.1423 8.64262ZM10.6839 27.9177C11.0307 28.0658 11.4828 28.0186 11.9758 27.739L22.9835 21.4839L20.05 18.5503L10.6839 27.9177ZM23.6852 21.0859L27.1395 19.1234C28.2257 18.5052 28.2257 17.4951 27.1395 16.8777L23.685 14.9148L20.5998 18.0005L23.6852 21.0859ZM22.9838 14.5163L11.9758 8.26133C11.4821 7.98076 11.0294 7.9341 10.6824 8.08309L20.0499 17.4506L22.9838 14.5163Z" fill="white"/>
               <defs>
                 <linearGradient id="paint0_linear_173_1682" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
                   <stop stop-color="#E61E42"/>
                   <stop offset="1" stop-color="#F5A26C"/>
                 </linearGradient>
               </defs>
             </svg>
             <span class="case-mobile-url-text" :style="{color:item.color}">Google-play</span>
           </a>
             <a v-if="item.url" :style="{background:item.color}" class="case-website-url" :href="item.url">
              <icon style="margin: auto 0">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="white"/>
                  <path d="M3.5127 9H20.4875" :stroke="item.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.5127 15H20.4874" :stroke="item.color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 20.759C14.0711 20.759 15.75 16.8377 15.75 12.0006C15.75 7.16346 14.0711 3.24219 12 3.24219C9.92893 3.24219 8.25 7.16346 8.25 12.0006C8.25 16.8377 9.92893 20.759 12 20.759Z" :stroke="item.color" stroke-width="1.5" stroke-miterlimit="10"/>
                </svg>
              </icon> <div style="margin: auto 0">Перейти на сайт</div>
             </a>
           </div>
         </div>
       </div>
     </div>
    </section>
  </div>
</template>
<script>
export default {
  name: 'cases',
  data(){
    return {
      filteredCase:[],
      currentFilter:'all',
      cases: [
        {
          type:'mobile',
          color:'#3591FD',
          name:'Wiedergeburt Business',
          description:'Сервис для предпринимателей открывает новые возможности: новости и события в сфере бизнеса, партнёрство и сотрудничество с иностранными компаниями, поиск сотрудников, переводческие услуг и другие сервисы, а также возможность вступить в члены бизнес-клуба немцев Казахстана.\n',
          devType:'Выполненные работы: Полный цикл разработки\n',
          tech:['PHP','MySQL','HTML/CSS3','ReactNative'],
          image:'./images/cases-pictures/wiedergeburt.png',
          url:'',
          appStore:'',
          googlePlay:'',

        },
        {
          type:'mobile',
          color:'#EE6158',
          name:'Gastreat',
          description:'Мобильное приложение для сети заведений Gastreat в разных городах Казахстана. Приложение имеет возможность просмотра меню ресторанов, зарезервировать стол с возможностью интерактивного выбора места посадки, накопительную программу лояльности и оплату счёта прямо в мобильном приложении.\n',
          devType:'Выполненные работы: Полный цикл разработки, интеграции (IIKO)',
          tech:['PHP','MySQL','HTML/CSS3','ReactNative','Redis'],
          image:'./images/cases-pictures/gastreat.png',
          url:'',
          appStore:'',
          googlePlay:'',

        },
        {
          type:'mobile',
          color:'#04C2F4',
          name:'Qabilet',
          description:'Мобильное приложение для  диагностики способностей и профориентации школьников Qabilet включает в себя тестирование способностей и консультирование по дальнейшему обучению и профессиональному развитию. Реализация проекта осуществляется корпоративным фондом «Академия Елбасы» при поддержке Фонда Нурсултана Назарбаева в рамках инициативы «El Úmiti»',
          devType:'Выполненные работы: Полный цикл разработки, интеграции (IIKO)',
          tech:['PHP','MySQL','HTML/CSS3','ReactNative','Redis'],
          image:'./images/cases-pictures/qabilet.png',
          url:'',
          appStore:'',
          googlePlay:'',

        },
        {
          type:'mobile',
          color:'#D23168',
          name:'Birge Oqy',
          description:'Мобильное приложение для обучения с использованием дистанционных образовательных технологий для учащихся сельских школ.',
          devType:'Выполненные работы: Полный цикл разработки, интеграции (IIKO)',
          tech:['PHP','MySQL','HTML/CSS3','ReactNative','Redis'],
          image:'./images/cases-pictures/birge-oqy-mobile.png',
          url:'',
          appStore:'',
          googlePlay:'',

        },
        {
          type:'mobile',
          color:'#11438A',
          name:'UMC OK',
          description:'Мобильное приложение для КГКП «Учебно-методического центра развития образования Карагандинской области» управления образования Карагандинской области. Включает в себя оформление подписки на электронный журнал организации, просмотр главных новостей, покупки методической продукции, просмотр списка открытых вакансий, интересных новостей и прохождение опросов. Для администраторов приложения реализован функционал по полному управлению контентом приложения. \n',
          devType:'Выполненные работы: Полный цикл разработки, сторонние интеграции (kaspi.kz)',
          tech:['Laravel','PHP','VueJS','ReactNative','MySQL'],
          image:'./images/cases-pictures/umc-ok.png',
          url:'',
          appStore:'',
          googlePlay:'',

        },
        {
          type:'site',
          color:'#3591FD',
          name:'REGHUB',
          description:'Информационный ресурс Регионального хаба по противодействию глобальным угрозам для Генеральной Прокуратуры Республики Казахстан\n',
          devType:'Выполненные работы: Полный цикл разработки\n',
          tech:['PHP','MySQL','HTML/CSS','WordPress'],
          image:'./images/cases-pictures/reghub.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#20367A',
          name:'Сайт Академии Елбасы',
          description:'Информационный ресурс для корпоративного фонда Академия Елбасы',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['PHP','HTML/CSS','MySQL','Laravel'],
          image:'./images/cases-pictures/elbasy-lending.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#3B9EEB',
          name:'Веб-сайт AGMP News',
          description:'Разработка информационного ресурса для ОЮЛ «Республиканская ассоциация горнодобывающих и горно- металлургических предприятий»\n',
          devType:'Выполненные работы: Полный цикл разработки\n',
          tech:['PHP','HTML/CSS3','MySQL','Wordpress(Elementor)'],
          image:'./images/cases-pictures/agmp.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#04C2F4',
          name:'Профориентационная служба Qabilet',
          description:'Разработка информационного ресурса для ОЮЛ «Республиканская ассоциация горнодобывающих и горно- металлургических предприятий»\n',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['PHP','HTML/CSS3','MySQL','Wordpress(Elementor)'],
          image:'./images/cases-pictures/qabilet-pc.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#7C8B96',
          name:'IBC (International Business Centre)',
          description:'Корпоративный веб сайт с платформой системой управления договорами и помещениями для компании «International Business Center» позволяет арендаторам подобрать одно или несколько помещений для аренды по своему желанию оставив заявку на сайте.',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['PHP','Laravel','MySQl','VueJs'],
          image:'./images/cases-pictures/ibc-site.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#06184A',
          name:'Информационный веб-сайт Qazgeology',
          description:'Сопровождение, и разработка рекламных материалов информационного ресурса Qazgeology.kz',
          devType:'Выполненные работы: Техническое сопровождение\n',
          tech:['Laravel','PHP','HTML/CSS3','MySql','Wordpress(Elementor)'],
          image:'./images/cases-pictures/Qazgeology.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'site',
          color:'#F3A935',
          name:'Сайт Службы центральных коммуникаций',
          description:'Поддержка информационного ресурса для РГУ «Служба центральных коммуникаций» при Президенте Республики Казахстан.',
          devType:'Выполненные работы: Техническое сопровождение\n',
          tech:['PHP','HTML/CSS','MySQL','Wordpress'],
          image:'./images/cases-pictures/comunication-site.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#20367A',
          name:'IBC (International Business Centre)',
          description:'Платформа позволяет заключать договора в системе привязывая помещения к договору и полностью видеть загрузку здания, расходы и прибыль с помощью интерактивной карты. Для администраторов приложения реализован функционал по полному управлению контентом приложения.',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение\n',
          tech:['PHP','Laravel','MySql','VueJs'],
          image:'./images/cases-pictures/ibc.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#2B43A3',
          name:'Камкор Сервис',
          description:'Система дистанционного обучения сотрудников по ремонту, модернизации, сервисному обслуживанию подвижных составов и инфраструктуры железных дорог для группы компаний «Камкор Менеджмент» позволяет проводить онлайн вебинары, опросы, тесты, загружать и скачивать дополнительные материалы необходимые для обучения как работникам, так и администраторам системы. Благодаря системе компания существенно сократила командировочные расходы преподавателей.',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение\n',
          tech:['PHP','HTML/CSS','MySQL','Memcached','Moodle'],
          image:'./images/cases-pictures/kamkor.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#095FA8',
          name:'Тумблер',
          description:'Система анализа данных и контроля учебного процесса для Компьютерной Академии ШАГ. Апробирована и внедрена в 7-ти филиалах Казахстана, Узбекистана, ОАЭ.<br><br>Система включает в себя следующие модули:<br><br>'+
          '<ul><li>Рейтинговая система студентов</li><li>Контроль и аналитика работы учебного отдела;\n</li><li>Модуль ведения кадровой документации сотрудников;\n</li><li>Модуль уведомлений через Telegram для всех сотрудников;\n</li><li>Модуль бухгалтера для контроля оплат;\n</li><li>Мониторинг работы всего филиала;\n</li><li>Модуль психолога;\n</li><li>Аналитика для отдела продаж;\n</li></ul>',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение\n',
          tech:['Laravel','PHP','MySQL','VueJS'],
          image:'./images/cases-pictures/it-step.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#20367A',
          name:'Система управления и контроля за пригородными пассажирскими перевозками',
          description:'Система контроля и управления пригородными пассажирскими перевозками для АО «Пригородные перевозки» предназначена для контроля и анализа пассажиропотока, мониторинга технического состояния электропоездов, ведения и учёта сотрудников, а также просмотр камер видеонаблюдения, находящихся в электропоездах в реальном времени. Так же доступна мобильная версия системы.',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['PHP','HTML/CSS','MySQL','Python'],
          image:'./images/cases-pictures/transfer-site.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'',
          name:'Библиотека архивных материалов (витрина)',
          description:'Система разработана для работы на интерактивной панели и отображению витрины архивных библиотечных материалов. Выполненные работы: Полный цикл разработки, техническое сопровождение',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['PHP','HTML/CSS','Python','MySQL','VueJS'],
          image:'./images/cases-pictures/library-archive.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#326D99',
          name:'Реестр ПО и продукции электронной промышленности\n',
          description:'Реестр ПО и продукции электронной промышленности создан в целях облегчения поиска информации о продукции, включенной в Реестр доверенного программного обеспечения и продукции электронной промышленности для потенциальных заказчиков, а также для получения информации о необходимых документах для потенциальных заявителей, чей продукт планируется включить в Реестр. Заказчиком проекта является Министерство цифрового развития, инноваций и аэрокосмической промышленности Казахстана.\n',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение, интеграции (Google Sheets, АО «НИТ», Documentolog)\n',
          tech:['PHP','Laravel','PostgreSQL','VueJS'],
          image:'./images/cases-pictures/mciriap.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#FF441F',
          name:'Texme',
          description:'Платформа позволяет пользователям находить оптимальный вариант из тысяч клиник и лабораторий, сравнив цены, расположение и удобное время приема.\n',
          devType:'Выполненные работы: Полный цикл разработки\n',
          tech:['PHP','Yii2','MySQL','HTML/CSS'],
          image:'./images/cases-pictures/texme.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#D23168',
          name:'Онлайн школа Birge Oqy',
          description:'Обучающая платформа с использованием дистанционных образовательных технологий для учащихся сельских школ.',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение',
          tech:['Python','Laravel','PHP','VueJS','MySql'],
          image:'./images/cases-pictures/birge-oqu.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#04C2F4',
          name:'Профориентационная служба Qabilet\n',
          description:'Cлужба диагностики способностей и профориентации школьников Qabilet включает в себя тестирование способностей и консультирование по дальнейшему обучению и профессиональному развитию. Реализация проекта осуществляется корпоративным фондом «Академия Елбасы» при поддержке Фонда Нурсултана Назарбаева в рамках инициативы «El Úmiti»\n',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение, интеграции (Национальная образовательная база данных)\n',
          tech:['Python','Laravel','PHP','VueJS','MySql'],
          image:'./images/cases-pictures/qabilet-portal.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#2A8BF2',
          name:'Платформа для Национальной олимпиады «Мын Бала»\n',
          description:'Уникальный проект национального масштаба, ориентированный на поддержку и развитие школьников, проживающих в сельской местности по всей стране. Проект реализовывался в рамках инициативы Елбасы El Úmiti. Инициатива предполагает поддержку молодых людей, начиная со школы и заканчивая продвинутыми программами обучения.\n',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение, интеграции (Национальная образовательная база данных)\n',
          tech:['Python','Laravel','PHP','VueJS','MySql'],
          image:'./images/cases-pictures/1000-bala.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#EB0568',
          name:'CG Service',
          description:'Система онлайн-тестирования по профориентации для анализа личности подростка и его карьерного типа. Тест поможет определить профессиональные области, где подросток сможет применить уже имеющиеся навыки и получит список рекомендаций, о том, что необходимо делать для того, чтобы определить и освоить желаемую профессию.\n',
          devType:'Выполненные работы: Полный цикл разработки, техническое сопровождение\n',
          tech:['PHP','Yii2','MySQL','HTML/CSS'],
          image:'./images/cases-pictures/cg-service.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
        {
          type:'portal',
          color:'#2E7BC2',
          name:'Система по отображению показателей платформ инициативы “El Úmiti”',
          description:'Система позволяет формировать умные отчеты в реальном времени, с помощью которых менеджеры проектов в рамках инициативы «El Úmiti» понимают, что прямо сейчас происходит с определенными показателями и группами показателей.\n',
          devType:'Выполненные работы: Полный цикл разработки\n',
          tech:['PHP','VueJS','MySql'],
          image:'./images/cases-pictures/dashboard.png',
          url:'',
          appStore:'',
          googlePlay:'',
        },
      ],
    }
  },
  watch:{
    'currentFilter'(){
      this.filterCase()
    }
  },

   mounted() {
   this.filteredCase=this.cases
    },
  methods:{
    filterCase(){
      if(this.currentFilter=="all"){
        this.filteredCase=this.cases
      }
      else {
        this.filteredCase=this.cases.filter(word => word.type == this.currentFilter);
      }

    },
  }
}
</script>